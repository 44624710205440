













import { Component, Vue, Provide } from 'vue-property-decorator';
import { APPIDS, COURSES_ROUTE_NAME } from '@/enums/';
import CoursesListFiltersComponent from '@/components/filters/coursesListFilters.component.vue';
import PaginationComponent from '@/components/pagination.component.vue';
import ScopeComponent from '@/components/scope.component.vue';
import CourseProvider from '@/providers/courses.provider';
import { CourseFilters } from '../../types/course.type';
import CourseList from '../../shared/components/CourseList.component.vue';

@Component({
  components: {
    CoursesListFiltersComponent,
    PaginationComponent,
    ScopeComponent,
    CourseList,
  },
})
export default class CoursesList extends Vue {
  @Provide() Provider = CourseProvider;

  appId = APPIDS.COURSES;

  name = 'CoursesList';

  filters: CourseFilters = {
    courseTypes: [],
    activeStatus: [
      { id: '1', name: 'active' },
      { id: '0', name: 'inactive' },
    ],
  };

  viewCourse(params) {
    this.$router.push({
      name: COURSES_ROUTE_NAME.VIEW,
      params: {
        courseId: params.courseId,
      },
    });
  }
}
